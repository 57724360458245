export const twelveMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const overlays = [
    { name: 'bb', label: 'Bollinger Bands', title: 'Bollinger Bands' },
    { name: 'ema', label: 'Exponential Moving Average', title: 'EMA' },
    { name: 'sma', label: 'Simple Moving Average', title: 'SMA' },
]

export const oscillators = [
    { name: 'macd', label: 'Moving Average Convergence Divergence', title: 'MACD' },
    { name: 'rsi', label: 'Relative Strength Index', title: 'RSI' },
]

export const AUTH0_DOMAIN = 'atc-apps.eu.auth0.com'
export const AUTH0_CLIENT_ID='4mTTUdTmo5TWFGC7ReQRB8hhBxEieW2h'
export const AUTH0_AUDIENCE='https://www.aramcotrading.sg'
export const WHATSAPP_LINK='https://wa.me/+6591259970'
export const APIURL = 'https://api.atc-apps.com/api'
export const AUDIO_MIME = 'audio/m4a'
export const HIGHCHARTS_EXPORTING_OPTIONS = {
    buttons: {
        contextButton: {
            align: 'left',
            menuItems: ["downloadPNG", "downloadPDF", "downloadCSV", "separator",]
        }
    }
}